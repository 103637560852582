$col-primary-50: #f0f9f6;
$col-primary-100: #d5e2de;
$col-primary-200: #c0c9c7;
$col-primary-300: #aabcba;
$col-primary-400: #94a8a6;
$col-primary-500: #839392;
$col-primary-600: #717c7c;
$col-primary-700: #4d5b5b;
$col-primary-800: #4a5151;
$col-primary-900: #313535;
$col-primary-950: #131414;
$col-blue: #008fc1;
$col-alert-light: #db0b3d;
$col-alert-dark: #96092b;
$col-pink: #f595ac;
$col-warning1-light: #ffc082;
$col-warning1-medium: #f3881d;
$col-warning1-dark: #b25900;
$col-warning2-light: #f4f493;
$col-warning2-medium: #eded15;
$col-warning2-dark: #b6a900;
$col-white: #ffffff;
$col-black: #000000;
