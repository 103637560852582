@use 'styles/abstracts' as *;

.container {
  margin: 7.5rem 0;
  width: 100%;
  height: 20rem;
  display: flex;
  @include breakpoint(tab-land) {
    margin: 5rem 0;
    height: 12.6875rem;
  }
  @include breakpoint(phone) {
    flex-flow: column;
    height: 29.6875rem;
    margin: 3.75rem 0;
  }
  .text {
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
    background-color: $col-primary-50;
    width: 40%;
    padding: 5rem 10rem 0 10rem;
    @include breakpoint(tab-land) {
      padding: 3rem 1.5rem;
      width: 50%;
      gap: 0.5rem;
    }
    @include breakpoint(phone) {
      padding: 2.5rem 1rem;
      width: 100%;
    }
  }
  .image {
    width: 60%;
    height: 100%;
    background-image: url('../../../assets/images/contacts.png');
    background-repeat: no-repeat;
    background-size: cover;
    @include breakpoint(tab-land) {
      width: 50%;
      background-image: url('../../../assets/images/contacts_tabland.png');
    }
    @include breakpoint(phone) {
      width: 100%;
      background-image: url('../../../assets/images/contacts_mobile.png');
    }
  }
}
