@use 'styles/abstracts' as *;

.button {
  margin-left: 3rem;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  font-family: 'Apfel Grotezk', sans-serif;
  cursor: pointer;
  .flag {
    width: 1.5rem;
    height: 1rem;
  }
}

.langButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  color: $col-black;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  input {
    visibility: hidden;
  }
  label {
    cursor: pointer;
    font-family: 'Apfel Grotezk', sans-serif;
  }
  .blue {
    color: #008fc1;
  }
  img {
    width: 1.5rem;
    height: 1rem;
  }
}
