@use 'styles/abstracts' as *;

.container {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0 10rem;
  @include breakpoint(tab-land) {
    padding: 0 3rem;
  }
  @include breakpoint(phone) {
    padding: 0 1rem;
  }
  h3 {
    margin-bottom: 1.5rem;
    @include breakpoint(phone) {
      margin-bottom: 1rem;
    }
  }
  .divider {
    height: 3.75rem;
    border-top: 1px solid $col-primary-900;
    @include breakpoint(phone) {
      height: 2.5rem;
    }
  }
  .cardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    @include breakpoint(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .card {
    background-color: $col-primary-50;
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 2.5rem 8.4375rem;
    h4 {
      word-break: break-all;
    }
    svg {
      path {
        fill: $col-black !important;
      }
    }
    @include breakpoint(tab-land) {
      gap: 0.5rem;
      padding: 2.5rem 1.5rem;
    }
    @include breakpoint(phone) {
      padding: 2.5rem 1rem;
    }
  }
}
