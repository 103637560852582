@use 'styles/abstracts' as *;

::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  display: none;
}
.bodyContainer {
  height: 100dvh;
  width: 100dvw;
  overflow: auto;
  display: flex;
  flex-flow: column;
  .privacyContainer {
    padding: 2rem 15%;
    @include breakpoint(tab-port) {
      padding: 1rem 1.5rem;
    }
    h1 {
      margin-bottom: 2.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      @include breakpoint(tab-port) {
        margin-bottom: 1.5rem;
      }
    }
    .subtitle {
      margin-bottom: 0.9375rem;
      h5 {
        color: $col-primary-500;
      }
    }
    p {
      margin-bottom: 0.9375rem;
    }
    ul {
      li {
        list-style: none;
      }
    }
    a {
      text-decoration: none;
      color: $col-primary-600;
    }
  }
}
