@use 'styles/abstracts' as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.125rem;
  .imageContainer {
    width: 100%;
    display: flex;
    flex-flow: column;
    h4 {
      word-break: break-word;
      margin-top: 1rem;
      @include breakpoint(phone) {
        margin-top: 0.5rem;
      }
    }
    img {
      max-width: 100%;
      @include breakpoint(phone) {
        height: 8.375rem;
      }
    }
  }
  h4 {
    word-break: break-word;
    margin-top: 1rem;
    @include breakpoint(phone) {
      margin-top: 0.5rem;
    }
  }
  .description {
    word-break: break-word;
  }
}
