@use 'styles/abstracts' as *;

.container {
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 70;
  top: 0;
  background-color: $col-white;
  padding: 0 1rem 0 10rem;
  @include breakpoint(tab-land) {
    padding: 0 1rem 0 3rem;
  }
  .logo {
    background-image: url('../../../assets/images/logo_eagleprojects_desktop.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 2.5rem;
    width: 9.25rem;
    @include breakpoint(tab-port) {
      background-image: url('../../../assets/images/logo_eagleprojects_mobile.png');
      height: 2rem;
      width: 7.375rem;
    }
  }
}
