@use 'styles/abstracts' as *;

.container {
  width: 100%;
  .title {
    padding: 7.5rem 10rem 0 10rem;
    @include breakpoint(tab-land) {
      padding: 5rem 3rem 0 3rem;
    }
    @include breakpoint(phone) {
      padding: 3.75rem 1rem 0 1rem;
    }
    h3 {
      margin-bottom: 1.5rem;
      @include breakpoint(phone) {
        margin-bottom: 1rem;
      }
    }
    .divider {
      height: 3.75rem;
      border-top: 1px solid $col-primary-900;
      @include breakpoint(phone) {
        height: 2.5rem;
      }
    }
  }
  .banner {
    width: 100%;
    display: flex;
    height: 25rem;
    @include breakpoint(phone) {
      flex-flow: column-reverse;
      height: 31.25rem;
    }
    .text {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      background-color: $col-primary-50;
      width: 50%;
      @include breakpoint(phone) {
        width: 100%;
      }
      h4 {
        padding: 5rem 10rem 0 10rem;
        @include breakpoint(tab-land) {
          padding: 5rem 3rem;
        }
        @include breakpoint(phone) {
          padding: 2.5rem 1rem;
        }
      }
      .imageText {
        background-image: url('../../../assets/images/robot_text_image.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 7.5rem;
        @include breakpoint(tab-land) {
          height: 3.75rem;
        }
      }
    }
    .image {
      width: 50%;
      height: 100%;
      background-image: url('../../../assets/images/robot.png');
      background-repeat: no-repeat;
      background-size: cover;
      @include breakpoint(tab-land) {
        background-image: url('../../../assets/images/robot_tabland.png');
      }
      @include breakpoint(phone) {
        width: 100%;
        background-image: url('../../../assets/images/robot_mobile.png');
      }
    }
  }
  .cardContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    padding: 7.5rem 21.1875rem 0 21.1875rem;
    @include breakpoint(tab-land) {
      padding: 5rem 3rem 0 3rem;
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(phone) {
      grid-template-columns: repeat(1, 1fr);
      padding: 3.75rem 1rem 0 1rem;
      grid-gap: 3.75rem;
    }
    .card {
      width: 100%;
      grid-gap: 1rem;
      @include breakpoint(phone) {
        grid-gap: 0.5rem;
      }
      h4 {
        word-break: break-all;
      }
      h5 {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p {
          text-transform: lowercase;
        }
      }
      img {
        max-width: 100%;
        margin-bottom: 3.75rem;
        @include breakpoint(tab-land) {
          margin-bottom: 1rem;
        }
        @include breakpoint(phone) {
          height: 21.4375rem;
        }
      }
    }
  }
}

.description {
  display: flex;
  flex-flow: column;
  gap: 3rem;
  @include breakpoint(tab-land) {
    gap: 2.5rem;
  }
  @include breakpoint(phone) {
    gap: 2rem;
  }
  .info {
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }
}
