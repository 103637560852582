@use 'styles/abstracts' as *;

.container {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 22.3125rem;
  @include breakpoint(tab-land) {
    height: 29.3125rem;
    margin-top: 5rem;
  }
  @include breakpoint(phone) {
    flex-direction: column;
    margin-top: 8rem;
  }
  .leftContainer {
    width: 100%;
    height: 100%;
    background-color: $col-primary-50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5.625rem 0 5.625rem 10rem;
    @include breakpoint(tab-land) {
      padding: 3.75rem 3rem 0 3rem;
      align-items: flex-start;
    }
    @include breakpoint(phone) {
      padding: 3.75rem 1rem;
    }
    h5 {
      margin-bottom: 1.25rem;
      align-self: flex-start;
      @include breakpoint(tab-port) {
        margin-bottom: 1rem;
      }
    }
    .subLeftContainer {
      display: flex;
      flex-direction: column;
      .subSubLeft {
        display: flex;
        gap: 5rem;
        @include breakpoint(tab-land) {
          flex-direction: column;
          gap: 1rem;
        }
        .subLeftLeft {
          padding-left: 1.5rem;
          width: 100%;
          border-left: solid 1px $col-primary-900;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          @include breakpoint(tab-land) {
            gap: 1.5rem;
          }
          p {
            width: 100%;
          }
          div {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            span {
              display: flex;
              flex-direction: column;
            }
          }
        }
        .subLeftRight {
          width: 100%;
          padding-left: 1.5rem;
          border-left: solid 1px $col-primary-900;
          display: flex;
          flex-direction: column;
          gap: 2rem;

          @include breakpoint(tab-land) {
            gap: 1.5rem;
          }
          div {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }
        }
      }
    }
  }
  .rightContainer {
    width: 100%;
    height: 100%;
    background-color: $col-primary-100;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 4rem 10rem;
    gap: 1.5rem;
    @include breakpoint(tab-land) {
      padding: 3.75rem 3rem;
    }
    @include breakpoint(phone) {
      gap: 1rem;
      padding: 6rem 1rem 3.75rem 1rem;
    }
    a {
      color: $col-blue;
      font-weight: 700;
    }
  }
}
