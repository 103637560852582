@use 'styles/abstracts' as *;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 7.5rem 10rem 0 10rem;
  @include breakpoint(tab-land) {
    padding: 5rem 3rem 0 3rem;
  }
  @include breakpoint(phone) {
    padding: 2.5rem 1rem 0 1rem;
  }
  h3 {
    margin-bottom: 1.5rem;
    @include breakpoint(phone) {
      margin-bottom: 1rem;
    }
  }
  .divider {
    height: 3.75rem;
    border-top: 1px solid $col-primary-900;
    @include breakpoint(phone) {
      height: 2.5rem;
    }
  }
  .cardContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
    @include breakpoint(tab-land) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include breakpoint(tab-port) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(phone) {
      grid-template-columns: repeat(2, 48%);
      grid-gap: 1rem;
    }
  }
  .card {
    width: 100%;
    grid-gap: 1rem;
    @include breakpoint(phone) {
      grid-gap: 0.5rem;
    }
    h4 {
      word-break: break-all;
    }
  }
}
