@use 'styles/abstracts' as *;

.hero {
  display: flex;
  position: relative;
  height: 100dvh;
  width: 100dvw;
  background-image: url('../../../assets/images/hero_bg_desktop.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint(tab-land) {
    background-image: url('../../../assets/images/hero_bg_tabland.png');
  }
  @include breakpoint(phone) {
    background-image: url('../../../assets/images/hero_bg_mobile.png');
  }
  .content {
    margin: 10rem 10rem 0 10rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 40%;
    transition: all 0.3s ease-in-out;
    @include breakpoint(tab-land) {
      margin: 10rem 3rem 0 3rem;
      max-width: 100%;
    }
    @include breakpoint(phone) {
      margin: 6rem 1rem;
      gap: 1rem;
      h3 {
        font-size: 1.25rem;
      }
    }
    span {
      margin-top: 1.125rem;
      @include breakpoint(tab-land) {
        margin-top: 0;
      }
    }
  }
}
