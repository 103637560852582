@use '../abstracts/' as *;

body {
  font-family: 'Apfel Grotezk', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  @include breakpoint(tab-land) {
    font-size: 0.8125rem;
  }
  b {
    font-weight: 700;
  }
}

h1 {
  font-size: 3.75rem;
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(tab-land) {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: 2.75rem;
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(tab-land) {
    font-size: 2.125rem;
  }
}

h3 {
  font-size: 2.125rem;
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(tab-land) {
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(tab-land) {
    font-size: 1.25rem;
  }
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint(tab-land) {
    font-size: 1rem;
  }
  b {
    color: $col-primary-600;
  }
}

p {
  font-size: 1.125rem;
  font-weight: 500;
  @include breakpoint(tab-land) {
    font-size: 1rem;
  }
  b {
    font-weight: 700;
  }
}

.sub {
  font-size: 1.25rem;
  font-weight: 500;
  @include breakpoint(tab-land) {
    font-size: 1.125rem;
  }
}

.blue {
  color: $col-blue;
}

.white {
  color: $col-white;
}

.black {
  color: $col-black;
}

.highlight {
  color: $col-blue !important;
  text-decoration: underline !important;
  text-underline-offset: 0.2rem !important;
}

.highlightMobile {
  border-left: 1px solid $col-blue !important;
  color: $col-blue !important;
}
