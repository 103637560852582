@use 'styles/abstracts' as *;

.container {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 7.5rem 10rem;
  @include breakpoint(tab-land) {
    padding: 5rem 3rem;
  }
  @include breakpoint(phone) {
    padding: 3.75rem 1rem;
  }
  h3 {
    margin-bottom: 1.5rem;
    @include breakpoint(phone) {
      margin-bottom: 1rem;
    }
  }
  .divider {
    height: 3.75rem;
    border-top: 1px solid $col-primary-900;
    @include breakpoint(phone) {
      height: 2.5rem;
    }
  }
  .cardContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    @include breakpoint(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .card {
    width: 100%;
    display: flex;
    position: relative;
    background-color: $col-white;
    z-index: 50;
    gap: 1rem;
    border: 1px solid $col-primary-400;
    align-items: center;
    padding: 2.5rem 8.4375rem;
    @include breakpoint(tab-land) {
      gap: 0.5rem;
      padding: 2.5rem 1.5rem;
    }
    @include breakpoint(phone) {
      padding: 2.5rem 1rem;
    }
  }
}
