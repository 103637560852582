@use 'styles/abstracts' as *;

.bodyContainer {
  height: 100dvh;
  width: fit-content;
  overflow: auto;
  .routesContainer {
    scroll-behavior: smooth;
    background-size: contain;
    .background {
      background-repeat: no-repeat;
      background-image: url('../../assets/images/karesansui.png');
      @include breakpoint(tab-land) {
        background-image: url('../../assets/images/karesansui_tabland.png');
      }
      @include breakpoint(phone) {
        background-image: url('../../assets/images/karesansui_mobile.png');
      }
    }
  }
}
