@use 'styles/abstracts' as *;

.container {
  margin: 0 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  @include breakpoint(tab-land) {
    margin: 0 1rem;
  }
  div {
    display: flex;
    gap: 7rem;
    align-items: center;
    justify-content: center;
    @include breakpoint(phone) {
      flex-direction: column;
      gap: 5.5rem;
    }
  }
}
