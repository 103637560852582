@use 'styles/abstracts' as *;

.generalContainer {
  width: 100dvw;
  display: flex;
  @include breakpoint(phone) {
    flex-flow: column;
  }
  .textContainer {
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
    background-color: $col-black;
    color: $col-white;
    width: 50%;
    padding: 5rem 10rem;
    a {
      color: $col-white;
    }
    @include breakpoint(tab-land) {
      padding: 5rem 3rem;
    }
    @include breakpoint(phone) {
      width: 100%;
      padding: 2.5rem 1rem;
      gap: 0.5rem;
    }
  }
  .image {
    width: 50%;
    background-image: url('../../../assets/images/aboutUs.png');
    background-repeat: no-repeat;
    background-size: cover;
    @include breakpoint(tab-land) {
      background-image: url('../../../assets/images/aboutUs_tabland.png');
    }
    @include breakpoint(phone) {
      width: 100%;
      height: 12.875rem;
      background-image: url('../../../assets/images/aboutUsMobile.png');
    }
  }
}
